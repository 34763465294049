import React from 'react';

import Translate from 'containers/Translate';
import Wizard from 'components/Wizard';
import { SpaceBetween } from 'components/SpaceBetween';

export const Retiree6530 = ({ setStep }) => {
  return (
    <Wizard title="65/30 Retiree" titleEs="Jubilado 65/30" back={ () => setStep("select-class") }>
      <SpaceBetween direction="vertical" alignment="center">
        <p>
          <Translate language="en">
            Contact Union Hall for instructions.
          </Translate>
          <Translate language="es">
            Contacte a la Unión para obtener instrucciones.
          </Translate>
        </p>
      </SpaceBetween>
    </Wizard>
  )
}