import React, {Component} from 'react';

import Panel from 'components/Panel';
import Text from 'components/Text'
import Translate from 'containers/Translate';

require('./index.scss');

export default class ContractorsView extends Component {

  render() {
    return(
      <div className="app-ContractorsView">
        <Panel noSidebar>
          <Text>
            <h2>
              <Translate language="en">Subcontracting</Translate>
              <Translate language="es">Subcontratando</Translate>
            </h2>
            <p>
              <Translate language="en">Attention General Contractors</Translate>
              <Translate language="es">Atenci&oacute;n Contratistas Generales</Translate>
            </p>
            <p>
              <Translate language="en">Are you seeking subcontractors who can complete your projects on time and correctly the first time? Look no further than union contractors.</Translate>
              <Translate language="es">¿Est&aacute; buscando subcontratistas que puedan completar sus proyectos a tiempo y correctamente la primera vez? No busque m&aacute;s que los contratistas de union.</Translate>
            </p>
            <p>
              <Translate language="en">Our union contractors come in all sizes, from large firms to small businesses, and are committed to excellence in performance. They adhere to all employment laws and ensure their workforce receives area-standard wages and benefits.</Translate>
              <Translate language="es">Nuestros contratistas de union vienen en todos los tama&ntilde;os, desde grandes empresas hasta peque&ntilde;os negocios, y est&aacute;n comprometidos con la excelencia en su desempe&ntilde;o. Cumplen con todas las leyes de empleo y garantizan que su fuerza laboral reciba salarios y beneficios est&aacute;ndares en el &aacute;rea.</Translate>
            </p>
            <h3>
              <Translate language="en">Contact Us</Translate>
              <Translate language="es">Contáctenos</Translate>
            </h3>
            <p>
              <Translate language="en">How to Contact Us during office hours:</Translate>
              <Translate language="es">Como contactarnos durante horas de oficina:</Translate>
            </p>
            <p>
              <b>
                <Translate language="en">
                  D.C. Area Address:<br/>
                  8500 Pennsylvania Avenue<br />
                  Upper Marlboro, MD 20772<br /><br />
                  D.C. Area Phone #: (301) 735-6660<br /><br />
                  Baltimore Area Address:<br/>
                  801 West Patapsco Ave<br />
                  Baltimore, MD 21230<br /><br />
                  Baltimore Area Phone #: (410) 355-5555<br/>
                </Translate>
                <Translate language="es">
                  Dirección del área de D.C.:<br/>
                  8500 Pennsylvania Avenue<br />
                  Upper Marlboro, MD 20772<br /><br />
                  Teléfono del área de D.C.: (301) 735-6660<br /><br />
                  Dirección del área de Baltimore:<br/>
                  801 West Patapsco Ave<br />
                  Baltimore, MD 21230<br /><br />
                  Teléfono del área de Baltimore: (410) 355-5555<br />
                </Translate>
              </b>
            </p>
          </Text>
        </Panel>
      </div>
    )
  }
}
