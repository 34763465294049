import React, {Component} from 'react';

import Panel from 'components/Panel';
import Text from 'components/Text';
import Translate from 'containers/Translate';

require('./index.scss');

export default class HomeView extends Component {

  render() {
    return(
      <div className="app-HomeView">
        <Panel>
          <Text>
            <h2>
              <Translate language="en">Who We Are</Translate>
              <Translate language="es">Quiénes Somos</Translate>
            </h2>
            <p>
              <Translate language="en">
                We are dedicated construction carpenters, deeply rooted in our community. Though we come from diverse backgrounds, we share a common commitment to excellence and service.
              </Translate>
              <Translate language="es">
                Somos carpinteros de construcción dedicados, profundamente arraigados en nuestra comunidad. Aunque venimos de diversos orígenes, compartimos un compromiso común con la excelencia y el servicio.
              </Translate>
            </p>
            <h2>
              <Translate language="en">Our Mission</Translate>
              <Translate language="es">Nuestra Misión</Translate>
            </h2>
            <Translate language="en">
              <p>We strive to provide the highest quality service to our members and support the union contractors who employ us. Our work spans a wide range of projects, including:</p>
              <ul>
                <li>Residential and commercial buildings</li>
                <li>Highway and industrial infrastructure</li>
                <li>Shopping centers</li>
                <li>Local, state, military, and government installations</li>
              </ul>
            </Translate>
            <Translate language="es">
              <p>Nos esforzamos por brindar el servicio de más alta calidad a nuestros miembros y apoyar a los contratistas sindicales que nos emplean. Nuestro trabajo abarca una amplia gama de proyectos, incluyendo:</p>
              <ul>
                <li>Edificios residenciales y comerciales</li>
                <li>Infraestructura vial e industrial</li>
                <li>Centros comerciales</li>
                <li>Instalaciones locales, estatales, militares y gubernamentales</li>
              </ul>
            </Translate>
            <h2>
              <Translate language="en">Our Values</Translate>
              <Translate language="es">Nuestros Valores</Translate>
            </h2>
            <p>
              <Translate language="en">
                We are committed to promoting excellence, strong work ethics, and core values in everything we do. Our certified apprenticeship program ensures that our members receive top-notch training.
              </Translate>
              <Translate language="es">
                Estamos comprometidos a promover la excelencia, una ética de trabajo sólida y valores fundamentales en todo lo que hacemos. Nuestro programa de aprendizaje certificado garantiza que nuestros miembros reciban una capacitación de primera categoría.
              </Translate>
            </p>
            <h2>
              <Translate language="en">Community Engagemenet</Translate>
              <Translate language="es">Participación Comunitaria</Translate>
            </h2>
             <p>
              <Translate language="en">
                We actively participate in social and civic activities, contributing to the well-being of our community. We are passionate about shaping a better future for working families.
              </Translate>
              <Translate language="es">
                Participamos activamente en actividades sociales y cívicas, contribuyendo al bienestar de nuestra comunidad. Nos apasiona dar forma a un futuro mejor para las familias trabajadoras.
              </Translate>
            </p>
          </Text>
        </Panel>
      </div>
    )
  }
}
