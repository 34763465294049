import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

class Static extends React.Component {

  constructor(props) {
    super(props);

    // let location = useLocation();
    // useEffect(
    //   () => {
    //     ReactGA.pageview(location.pathname, document.title)
    //   },
    //   [location]
    // )
  }

  render () {
    return null;
  }
}

export default Static;
