import React, {Component} from 'react';

import Panel from 'components/Panel';
import { OnlinePaymentWizardHome } from './wizad/Home';
import { NotAMember } from './wizad/NotAMember';
import { SelectClass } from './wizad/SelectClass';
import { JourneymanApprentice } from './wizad/JourneymanApprentice';
import { Retiree6530 } from './wizad/Retiree6530';
import { ConfirmActiveStatus } from './wizad/ConfirmActiveStatus';
import { SuspendedMembership } from './wizad/SuspendedMembership';


require('./index.scss');

const PayDuesOnline = () => {
  const [step, setStep] = React.useState("home");

  return (
    <div className="app-PayDuesOnline">
      <Panel noSidebar>
        {step === "home" && <OnlinePaymentWizardHome setStep={setStep} />}
        {step === "not-a-member" && <NotAMember setStep={setStep} />}
        {step === "confirm-active-status" && <ConfirmActiveStatus setStep={setStep} />}
        {step === "suspended-membership" && <SuspendedMembership setStep={setStep} />}
        {step === "select-class" && <SelectClass setStep={setStep} />}
        {step === "journeyman-apprentice" && <JourneymanApprentice setStep={setStep} />}
        {step === "65-30-retiree" && <Retiree6530 setStep={setStep} />}
      </Panel>
    </div>
  )
}

export default PayDuesOnline;
