import React, {Component} from 'react';

import { NavLink } from 'react-router-dom';
import Translate from 'containers/Translate';

export default class Nav extends Component {
  render() {
    return (
      <div>
        <NavLink className={({ isActive }) => `app-Header-link${isActive ? "-active" : ""}`} to="/">
          <Translate language="en">
            Home
          </Translate>
          <Translate language="es">
            Entrada
          </Translate>
        </NavLink>
        <NavLink className={({ isActive }) => `app-Header-link${isActive ? "-active" : ""}`} to="/about">
          <Translate language="en">
            About
          </Translate>
          <Translate language="es">
            Acerca De
          </Translate>
        </NavLink>
        <NavLink className={({ isActive }) => `app-Header-link${isActive ? "-active" : ""}`} to="/pay-dues">
          <Translate language="en">
            Pay Dues
          </Translate>
          <Translate language="es">
            Pagar Cuotas
          </Translate>
        </NavLink>
        <NavLink className={({ isActive }) => `app-Header-link${isActive ? "-active" : ""}`} to="/contractors">
          <Translate language="en">
            Contractors
          </Translate>
          <Translate language="es">
            Contratistas
          </Translate>
        </NavLink>
        <NavLink className={({ isActive }) => `app-Header-link${isActive ? "-active" : ""}`} to="/politics">
          <Translate language="en">
            Politics
          </Translate>
          <Translate language="es">
            Politicas
          </Translate>
        </NavLink>
        <NavLink className={({ isActive }) => `app-Header-link${isActive ? "-active" : ""}`} to="/area-standards">
          <Translate language="en">
            Standards
          </Translate>
          <Translate language="es">
            Estandares
          </Translate>
        </NavLink>
        <NavLink className={({ isActive }) => `app-Header-link${isActive ? "-active" : ""}`} to="/abuse">
          <Translate language="en">
            Abuse
          </Translate>
          <Translate language="es">
            Abuso
          </Translate>
        </NavLink>
        <NavLink className={({ isActive }) => `app-Header-link${isActive ? "-active" : ""}`} to="/training">
          <Translate language="en">
            Training
          </Translate>
          <Translate language="es">
            Entrenamiento
          </Translate>
        </NavLink>
      </div>
    )
  }
}