import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Translate from 'containers/Translate'
import Button from 'components/Button'
import { SpaceBetween } from 'components/SpaceBetween'

require('./index.scss')

export default class Wizard extends Component {
  static propTypes = {
    sidebar: PropTypes.bool,
    grow: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  }

  render() {
    return (
      <div className={`app-Wizard${this.props.grow ? "-grow" : ""} ${[this.props.className]}`}>
        <span className="app-Wizard-title">
          {this.props.back && (
            <Button className="app-Wizard-back" primary click={this.props.back}>
              <img src={require("./back.png")} />
            </Button>
          )}
          <Translate language="en">
            {this.props.title}
          </Translate>
          <Translate language="es">
            {this.props.titleEs}
          </Translate>
        </span>
        {this.props.children}
      </div>
    )
  }
}
