import React from 'react';

import Translate from 'containers/Translate';
import Wizard from 'components/Wizard';
import { SpaceBetween } from 'components/SpaceBetween';
import Snippet from 'components/Snippet';
import Button from 'components/Button';
import Note from 'components/Note';

export const JourneymanApprentice = ({ setStep }) => {
  return (
    <Wizard title="Journeyman / JourneymanApprentice" titleEs="Oficial / Aprendiz" back={ () => setStep("select-class") }>
      <SpaceBetween direction="vertical" alignment="center" size="l">
        <Snippet heading={
          <>
            <Translate language="en">
              Journeyman / apprentice dues - $20/month
            </Translate>
            <Translate language="es">
              Cuotas de oficial / aprendiz - $20/mes
            </Translate>
          </>
        }>
          <Translate language="en">ALL CREDIT CARD TRANSACTIONS INCLUDE A $3.00 SURCHARGE PER TRANSACTION.</Translate>
          <Translate language="es">TODAS LAS TRANSACCIONES CON TARJETA DE CRÉDITO INCLUYEN UN RECARGO DE $3.00 POR TRANSACCIÓN.</Translate>
        </Snippet>
        <p>
          <Translate language="en">
            Select a payment term:
          </Translate>
          <Translate language="es">
            Seleccione un plazo de pago:
          </Translate>
        </p>
        <Button
          secondary
          click={() => window.open("https://haynow.appcapable.com/customerForm?paymentFormId=66bcc2627c40c43290469d66", "_self")}
        >
          <Translate language="en">
            Journeyman / Apprentice Yearly Payment = $243
          </Translate>
          <Translate language="es">
            Pago anual de oficial / aprendiz = $243
          </Translate>
        </Button>
        <Button
          secondary
          click={() => window.open("https://haynow.appcapable.com/customerForm?paymentFormId=66bcc1947c40c43290469d64", "_self")}
        >
          <Translate language="en">
            Journeyman / Apprentice 6 Month Payment = $123
          </Translate>
          <Translate language="es"> 
            Pago de 6 meses de oficial / aprendiz = $123
          </Translate>
        </Button>
        <Button
          secondary
          click={() => window.open("https://haynow.appcapable.com/customerForm?paymentFormId=66bcbf247c40c43290469d60", "_self")}
        >
          <Translate language="en">
            Journeyman / Apprentice Quarterly Payment = $63
          </Translate>
          <Translate language="es">
            Pago trimestral de oficial / aprendiz = $63
          </Translate>
        </Button>
        <Note>
          <p>
            <Translate language="en">A RECEIPT WILL BE EMAILED TO THE EMAIL ADDRESS YOU INPUT ON THE PAYMENT SCREEN.</Translate>
            <Translate language="es">SE LE ENVIARÁ UN RECIBO POR CORREO ELECTRÓNICO A LA DIRECCIÓN DE CORREO ELECTRÓNICO QUE INGRESE EN LA PANTALLA DE PAGO.</Translate>
          </p>
        </Note>
      </SpaceBetween>
    </Wizard>
  )
}