import clsx from 'clsx';
import React from 'react';

require('./index.scss');

export const SpaceBetween = ({ children, direction, size, alignment, align }) => (
  <div
    className={clsx([
      "SpaceBetween",
      `SpaceBetween-${direction ?? "vetical"}`,
      `SpaceBetween-${size ?? "s"}`,
      `SpaceBetween-align-${alignment ?? align ?? "start"}`
    ])}
    >
      {Array.isArray(children) ? children.map(child => {
        const key = typeof child === 'object' ? child.key : undefined;

        return (
          <div key={key} className={`SpaceBetween-child`}>
            {child}
          </div>
        );
      }) : children}
    </div>
)