import React, {Component} from 'react';
import { ReactComponent as GithubSVG } from 'assets/inline/github.svg';

require('./index.scss');

export default class Footer extends Component {

  render() {
    return(
      <div className="app-Footer">
        <div className="app-Footer-content">
          <div className="app-Footer-content-social">
            <a href="https://github.com/Local197" rel="noopener" target="__blank">
              <GithubSVG />
            </a>
          </div>
          <div>
            <span className="app-Footer-content-copy">2016 - 2024 &copy; UBC Local 197</span>
          </div>
        </div>
      </div>
    );
  }
}
