import React from 'react';

import Translate from 'containers/Translate';
import Hero from 'components/Hero';
import Button from 'components/Button';
import Wizard from 'components/Wizard';
import { SpaceBetween } from 'components/SpaceBetween';

export const OnlinePaymentWizardHome = ({ setStep }) => {
  return (
    <Wizard title="Online Dues Payment" titleEs="Pago de Cuotas en Línea">
      <SpaceBetween direction="vertical" alignment="center">
        <Hero
          backgroundImage={require('./images/hero-background.jpg')}
          iconImage={require('./images/stopsign.png')}
        >
          <h2 style={{color: "white"}}>
            <Translate language="en">LOCAL UNION 197 MEMBERS ONLY</Translate>
            <Translate language="es">MIEMBROS DEL LOCAL UNION 197 SOLAMENTE</Translate>
          </h2>
          <hr />
          <p style={{color: "white"}}>
            <Translate language="en">
              THIS PAYMENT SYSTEM IS FOR LOCAL UNION 197 MEMBERS ONLY!<br /><br />
              <b>IF YOU ARE NOT A MEMBER OF LOCAL UNION 197, PAYMENTS CANNOT BE APPLIED TO YOUR DUES.</b><br /><br /> 
              (YOU WILL NEED TO CONTACT YOUR LOCAL UNION DIRECTLY TO PAY DUES)
            </Translate>
            <Translate language="es">
              ¡ESTE SISTEMA DE PAGO ES SOLO PARA MIEMBROS DEL LOCAL UNION 197!<br /><br />
              <b>SI NO ES MIEMBRO DEL LOCAL UNION 197, LOS PAGOS NO SE PUEDEN APLICAR A SUS CUOTAS.</b><br /><br />
              (DEBERÁ CONTACTAR A SU LOCAL UNION DIRECTAMENTE PARA PAGAR CUOTAS)
            </Translate>
          </p>
        </Hero>
        <p>
          <Translate language="en">
            Click on one of the options below:
          </Translate>
          <Translate language="es">
            Haga clic en una de las opciones a continuación:
          </Translate>
        </p>
        <SpaceBetween direction="horizontal" size="l" alignment="center">
          <Button
            click={() => setStep("confirm-active-status") }
          >
            <Translate language="en">
              I am a member of Local Union 197
            </Translate>
            <Translate language="es">
              Soy miembro del Local Union 197
            </Translate>
          </Button>
          <Button
            click={() => setStep("not-a-member") }
            secondary
          >
            <Translate language="en">
              I am NOT a member of Local Union 197
            </Translate>
            <Translate language="es">
              NO soy miembro del Local Union 197
            </Translate>
          </Button>
        </SpaceBetween>
      </SpaceBetween>
    </Wizard>
  )
}