import React from 'react';
import 'App.scss';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import ReactGA from 'react-ga';
import { createStore, compose, applyMiddleware } from '@reduxjs/toolkit';
import reducer from 'reducers';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { push as Menu } from 'react-burger-menu'

import Footer from 'components/Footer';
import Static from 'containers/Static';
import AboutUsView from 'views/AboutUs';
import AreaStandardsView from 'views/AreaStandards';
import SignUpView from 'views/SignUp';
import ContractorsView from 'views/Contractors';
import HomeView from 'views/Home';
import PoliticalCornerView from 'views/PoliticalCorner';
import TrainingView from 'views/Training';
import WorkerAbuseView from 'views/WorkerAbuse';
import DashboardView from 'views/Dashboard';
import Nav from 'components/nav';
import Panel from 'components/Panel';
import PayDues from 'views/PayDues';
import PayDuesOnline from 'views/PayDues/online';

/**
|--------------------------------------------------
| Google Analytics init &  middleware
|--------------------------------------------------
*/
// const ga_token = document?.getElementById("root")?.getAttribute('data-ga-token');
// ReactGA.initialize(ga_token || "");
// const ga = () => (next: (arg0: any) => any) => (action: { type: any; }) => {
//   ReactGA.event({
//     category: 'REDUX_ACTION',
//     action: action.type
//   });
//   return next(action);
// }

/**
|--------------------------------------------------
| REDUX Store set-up
|--------------------------------------------------
*/
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, /* preloadedState, */ composeEnhancers(
  applyMiddleware(promiseMiddleware, thunk)
));

/**
|--------------------------------------------------
| Hook up react app to root div
|--------------------------------------------------
*/
function App() {
  return (
    <>
    <Provider store={store}>
    <BrowserRouter>
      <div>
        <Menu right pageWrapId={ "page-wrap" } outerContainerId={ "root" }>
          <Nav />
        </Menu>
        <div className="app" id="page-wrap">
          <Routes>
            <Route
              path="/" 
              element={<HomeView />}>
            </Route>
            <Route
              path="/about"
              element={<AboutUsView />}>
            </Route>
            <Route
              path="/area-standards"
              element={<AreaStandardsView />}>
            </Route>
            <Route
              path="/contractors"
              element={<ContractorsView />}>
            </Route>
            <Route
              path="/member-benefits"
              element={<SignUpView />}>
            </Route>
            <Route
              path="/politics"
              element={<PoliticalCornerView />}>
            </Route>
            <Route
              path="/training"
              element={<TrainingView />}>
            </Route>
            <Route
              path="/abuse"
              element={<WorkerAbuseView />}>
            </Route>
            <Route
              path="/dashboard"
              element={<DashboardView />}>
                </Route>
            <Route
              path="/pay-dues"
              element={<PayDues />}>
            </Route>
            <Route
              path="/pay-dues/online"
              element={<PayDuesOnline />}>
            </Route>
          </Routes>
          <Footer />
          <Static />
        </div>
      </div>
    </BrowserRouter>
  </Provider></>
  );
}

export default App;
