import React, {Component} from 'react';
import { Link, NavLink } from 'react-router-dom';
// import { Storage } from 'aws-amplify';
import {
  Route
} from 'react-router-dom';

import SignIn from 'containers/SignIn';
import SignUp from 'components/SignUp';
import Button from 'components/Button';
import Note from 'components/Note';
import Translate from 'containers/Translate';
import HeaderContainer from 'containers/HeaderContainer';

// import { Auth } from 'aws-amplify';

require('./index.scss');

export default class Panel extends Component {

  constructor(props) {
    super(props);
    this._generateAuthLinks = this._generateAuthLinks.bind(this);
    this._signOutSubmit = this._signOutSubmit.bind(this);
  }

  render() {
    return(
      <div className="app-Panel">
        {this.props.noHeader ? null :
          <HeaderContainer />
        }
        <div className="app-Panel-container">
          <div className={`app-Panel-content ${this.props.className}`}>
            {this.props.children}
          </div>

          { this.props.noSidebar == true ? null :
            <div className="app-Panel-sidebar">
              {this._generateAuthLinks()}
              <Translate language="en">
                <iframe src="https://www.youtube.com/embed/3-X5hCxdaLc" frameBorder="0" allowFullScreen></iframe>
              </Translate>
              <Translate language="es">
                <iframe src="https://www.youtube.com/embed/iaBCBXy600E" frameBorder="0" allowFullScreen></iframe>
              </Translate>
              <div className="app-Panel-sidebar-links">
                {
                // <Link className="app-Panel-sidebar-link" to="/">
                //   <Translate language="en">
                //     Join our Movement
                //   </Translate>
                //   <Translate language="es">
                //     Unete al Movimiento
                //   </Translate>
                // </Link>
                }
                <a className="app-Panel-sidebar-link" href="https://www.facebook.com/UBC197/" target="__blank">
                  <Translate language="en">
                    Facebook
                  </Translate>
                  <Translate language="es">
                    Facebook
                  </Translate>
                </a>
                <a className="app-Panel-sidebar-link" href="http://blog.197-carpenters.com/" target="__blank">
                  <Translate language="en">
                    View our Blog
                  </Translate>
                  <Translate language="es">
                    Lee Nuestro Blog
                  </Translate>
                </a>            
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  _openLink (key, level) {
    Storage.get(key, { 
      level,
      expires: 5
    })
    .then(result => {
      window.open(result);
    });
  }

  _generateAuthLinks() {
    if (this.props.state == 'signed_in') {
      return (
        <div style={{textAlign: "center"}}>
          <div className="app-Panel-sidebar-auth">
            <Translate language="en">
              Welcome
            </Translate>
            <Translate language="es">
              ¡Bienvenido
            </Translate>
            <span> {this.props &&
                    this.props.attributes &&
                    this.props.attributes.name ?
                    this.props.attributes.name :
                    this.props.username}
            !</span>
          </div>
          <a className="app-Panel-sidebar-link" href="https://local197-public-website-files.s3.amazonaws.com/pdf/197-ratified-bylaws.pdf" target="__blank">
            <Translate language="en">
              Ratified Bylaws
            </Translate>
            <Translate language="es">       
              Estatutos Ratificados
            </Translate>
          </a>
          <a className="app-Panel-sidebar-link" href="https://local197-public-website-files.s3.amazonaws.com/pdf/privacy-policy.pdf" target="__blank">
            <Translate language="en">
              Privacy Policy
            </Translate>
            <Translate language="es">       
              Política de Privacidad
            </Translate>
          </a>
          <button
            onClick={this._signOutSubmit}
            className="app-Panel-sidebar-button">
            <Translate language="en">
              Logout
            </Translate>
            <Translate language="es">
              Cerrar Sesi&oacute;n
            </Translate>
          </button>
          <Link className="app-Panel-sidebar-button" to="/dashboard">
            <Translate language="en">
            My Dashboard
            </Translate>
            <Translate language="es">
            Mi Página
            </Translate>
          </Link>
        </div>
      );
    }
    return (
      <div>
        <NavLink className="app-Panel-sidebar-link" h to="/pay-dues">
          <Translate language="en">
            Pay Dues
          </Translate>
          <Translate language="es">
            Pagar Cuotas
          </Translate>
        </NavLink>
        <a className="app-Panel-sidebar-link" href="https://local197-public-website-files.s3.amazonaws.com/pdf/197-ratified-bylaws.pdf" target="__blank">
          <Translate language="en">
            Ratified Bylaws
          </Translate>
          <Translate language="es">       
            Estatutos Ratificados
          </Translate>
        </a>
        <Translate language="en">
          <a className="app-Panel-sidebar-link" href="https://marcc.carpenters.fund/" target="__blank">
            MARCC Benefits
          </a>
        </Translate>
        <Translate language="es">       
          <a className="app-Panel-sidebar-link" href="https://marcc.carpenters.fund/" target="__blank">
            Beneficios de MARCC
          </a>
        </Translate>
        <a className="app-Panel-sidebar-link" href="https://local197-public-website-files.s3.amazonaws.com/pdf/privacy-policy.pdf" target="__blank">
          <Translate language="en">
            Privacy Policy
          </Translate>
          <Translate language="es">       
            Política de Privacidad
          </Translate>
        </a>
      </div>
    );
  }

  _signOutSubmit() {
    this.props.signOut()
  }
}
