import React, {Component} from 'react';

import Panel from 'components/Panel';
import Translate from 'containers/Translate';
import Hero from 'components/Hero';
import Text from 'components/Text';
import Button from 'components/Button';
import { NavLink, useNavigate } from 'react-router-dom';

require('./index.scss');

const PayDues = () => {
  const navigate = useNavigate();

  return(
    <div className="app-PayDues">
      <Panel noSidebar>
        <Text variant="wide">
          <h2>
            <Translate language="en">Paying Dues</Translate>
            <Translate language="es">Pagando Cuotas</Translate>
          </h2>
          <p>
            <Translate language="en">
              Dues can be paid monthly, quarterly, or yearly. Dues can be paid by one of the following five ways. Dues should be paid prior to the start of the quarter.
            </Translate>
            <Translate language="es">
              Las cuotas se pueden pagar mensualmente, trimestralmente, o anualmente. Las cuotas se pueden pagar de una de las siguientes cinco formas. Las cuotas deben pagarse antes del comienzo del trimestre.
            </Translate>
          </p>
          <h2>
            <Translate language="en">1. Online payments (PC, laptop, or smartphone)</Translate>
            <Translate language="es">1. Pagos en línea (PC, computadora portátil, o teléfono inteligente)</Translate>
          </h2>
          <p>
            <Translate language="en">
              You are now able to pay your dues online. Check your status on your TVC card, if you are SUSPENDED you cannot pay online and must call <a href="tel:3017356660">(301) 735-6660</a> for the DC Area, and <a href="tel:4103555555">(410) 355-5555</a>, for the Baltimore Area.
            </Translate>
            <Translate language="es">
              Ahora puede pagar sus cuotas en línea. Verifique su estado en su tarjeta TVC, si está SUSPENDIDO no puede pagar en línea y debe llamar al <a href="tel:3017356660">(301) 735-6660</a> para el área de DC y al <a href="tel:4103555555">(410) 355-5555</a> para el área de Baltimore.
            </Translate>
          </p>
          <h3>
            <Translate language="en">Select your status</Translate>
            <Translate language="es">Seleccione su estado</Translate>
          </h3>
          <ul>
            <li>
              <Translate language="en">Journeyman / Apprentice $20/month</Translate>
              <Translate language="es">Oficial / Aprendiz $20/mes</Translate>
            </li>
            <li>
              <Translate language="en">65/30 Retiree - contact union hall for instructions</Translate>
              <Translate language="es">Jubilado 65/30 - comuníquese con la sede del sindicato para obtener instrucciones</Translate>
            </li>
          </ul>
          <h3>Select your duration</h3>
          <ul>
            <li>
              <Translate language="en">Yearly (12 months)</Translate>
              <Translate language="es">Anual (12 meses)</Translate>
            </li>
            <li>
              <Translate language="en">6 months</Translate>
              <Translate language="es">6 meses</Translate>
            </li>
            <li>
              <Translate language="en">Quarterly (3 months)</Translate>
              <Translate language="es">Trimestral (3 meses)</Translate>
            </li>
          </ul>
          <p>
            <b>
              <Translate language="en">**Every option will include a $3 surcharge fee**</Translate>
              <Translate language="es">**Cada opción incluirá una tarifa de recargo de $3**</Translate>
            </b>
          </p>
          <p>
            <b>
              <Translate language="en">**The fee will be included if paying at the meeting, online, or over the phone**</Translate>
              <Translate language="es">**La tarifa se incluirá si paga en la reunión, en línea, o por teléfono**</Translate>
            </b>
          </p>
          <h3>
            <Translate language="en">Fill out the Form</Translate>
            <Translate language="es">Complete el formulario</Translate>
          </h3>
          <p>
            <Translate language="en">
              Every box must be filled out correctly and your UBC# must be filled out in the note box (XXXX-XXXX) to be able to proceed to the payment screen.
            </Translate>
            <Translate language="es">
              Cada casilla debe completarse correctamente y su número de UBC debe completarse en la casilla de nota (XXXX-XXXX) para poder proceder a la pantalla de pago.
            </Translate>
          </p>
          <h3>
            <Translate language="en">Credit card information</Translate>
            <Translate language="es">Información de la tarjeta de crédito</Translate>
          </h3>
          <p>
            <Translate language="en">
              Fill out the credit card information. If you enter incorrect information or have insufficient funds it will not complete your payment. Once successful you will be emailed a receipt. Keep receipt for your personal records.
            </Translate>
            <Translate language="es">
              Complete la información de la tarjeta de crédito. Si ingresa información incorrecta o no tiene fondos suficientes, no completará su pago. Una vez exitoso, se le enviará un recibo por correo electrónico. Guarde el recibo para sus registros personales.
            </Translate>
          </p>
          <Button click={() => navigate("online")}>
            <b>
              <Translate language="en">CLICK HERE TO PAY DUES ONLINE</Translate>
              <Translate language="es">HAGA CLIC AQUÍ PARA PAGAR LAS CUOTAS EN LÍNEA</Translate>
            </b>
          </Button>
          <h2>
            <Translate language="en">2. Credit Cards</Translate>
            <Translate language="es">2. Tarjetas de Crédito</Translate>
          </h2>
          <p>
            <Translate language="en">
              <i>Please call <a href="tel:3017356660">(301) 735-6660</a> for the DC Area, and <a href="tel:4103555555">(410) 355-5555</a>, for the Baltimore Area.</i><br />
              Leave your name and phone number if you get the answering machine – you will receive a call back.<br /><br />
              <b>DO NOT LEAVE YOUR CREDIT CARD NUMBER ON THE MACHINE</b><br /><br />
              <b>**Every credit card payment will include a $3 surcharge fee.  The fee will be included if paying at the meeting, online, or over the phone**</b>
            </Translate>
            <Translate language="es">
              <i>Por favor llame al <a href="tel:3017356660">(301) 735-6660</a> para el área de DC y al <a href="tel:4103555555">(410) 355-5555</a> para el área de Baltimore.</i><br />
              Deje su nombre y número de teléfono si obtiene el contestador automático – recibirá una llamada de vuelta.<br /><br />
              <b>NO DEJE SU NÚMERO DE TARJETA DE CRÉDITO EN LA MÁQUINA</b><br /><br />
              <b>**Cada pago con tarjeta de crédito incluirá una tarifa de recargo de $3.  La tarifa se incluirá si paga en la reunión, en línea, o por teléfono**</b>
            </Translate>
          </p>
          <h2>
            <Translate language="en">3. Mail</Translate>
            <Translate language="es">3. Correo</Translate>
          </h2>
          <p>
            <Translate language="en">
              Payable to: <br/>
              <i>Local Union #197 - Write your UBC# on the check.</i><br /><br />
              <img src={require("./wizad/images/check.jpg")} />
              <br /><br />
              Mail to:<br/><br/>
              <i>Attention: Raul Castro- Financial Secretary</i> <br />
              <i>8500 Pennsylvania Ave.</i><br/>
              <i>Upper Marlboro, MD 20772</i><br/><br/>
              <b>**DO NOT send your training verification card or dues book**</b>
            </Translate>
            <Translate language="es">
              Pagar a: <br/>
              <i>Local Union #197 - Escriba su número de UBC en el cheque.</i><br /><br />
              <img src={require("./wizad/images/check.jpg")} />
              <br /><br />
              Envíelo a:<br/><br/>
              <i>Atención: Raul Castro- Secretario Financiero</i> <br />
              <i>8500 Pennsylvania Ave.</i><br/>
              <i>Upper Marlboro, MD 2077</i><br/><br/>
              <b>**NO envíe su tarjeta de verificación de entrenamiento o libro de cuotas**</b>
            </Translate>
          </p>
          <h2>
            <Translate language="en">4. EFT – (Electronic Funds Transfer)</Translate>
            <Translate language="es">4. EFT – (Transferencia Electrónica de Fondos)</Translate>
          </h2>
          <p>
            <Translate language="en">
              This option will be available in the near future.
            </Translate>
            <Translate language="es">
              Esta opción estará disponible en un futuro próximo.
            </Translate>
          </p>
          <h2>
            <Translate language="en">5. Pay at the meeting</Translate>
            <Translate language="es">5. Pagar en la reunión</Translate>
          </h2>
          <p>
            <Translate language="en">
              Check, money order, or use a credit card.<br/><br/>
              <b>NO CASH WILL ACCEPTED</b>
            </Translate>
            <Translate language="es">
              Cheque, giro postal, o use una tarjeta de crédito.<br/><br/>
              <b>NO SE ACEPTARÁ EFECTIVO</b>
            </Translate>
          </p>
          <hr />
          <h2>REINSTATEMENT  POLICY</h2>
          <p>
            <Translate language="en">
              As of January 1, 2020, the International has changed its reinstatement policy for suspended members (suspension happens when dues are not paid for 6 months):<br/><br/>
              If you suspend after January 1, 2020 for the first time, you can be reinstated  by paying all back dues and fees.<br/><br/>
              If you suspend the 2nd time after January 1, 2020, you can only be REINITIATED. When this happens, your service time goes to zero.<br /><br />
            </Translate>
            <Translate language="es">
              A partir del 1 de enero de 2020, el Internacional ha cambiado su política de reinstalación para miembros suspendidos (la suspensión ocurre cuando las cuotas no se pagan durante 6 meses):<br/><br/>
              Si suspende después del 1 de enero de 2020 por primera vez, puede ser reinstalado pagando todas las cuotas atrasadas y tarifas.<br/><br/>
              Si suspende la 2da vez después del 1 de enero de 2020, solo puede ser REINICIADO. Cuando esto sucede, su tiempo de servicio se va a cero.
            </Translate>
          </p>
          <ul>
            <li>
              <Translate language="en">Journeyman: $300.00 Initiation fee</Translate>
              <Translate language="es">Oficial: $300.00 Cuota de iniciación</Translate>
            </li>
            <li>
              <Translate language="en">50.00 Prior Member Fee</Translate>
              <Translate language="es">50.00 Cuota de miembro anterior</Translate>
            </li>
            <li>
              <Translate language="en">$350.00 plus dues</Translate>
              <Translate language="es">$350.00 más cuotas</Translate>
            </li>
          </ul>
        </Text>
      </Panel>
    </div>
  )
}


export default PayDues;