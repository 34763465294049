import React from 'react';

import Translate from 'containers/Translate';
import Button from 'components/Button';
import Wizard from 'components/Wizard';
import { SpaceBetween } from 'components/SpaceBetween';
import Note from 'components/Note';

require('./SelectClass.scss');

export const SelectClass = ({ setStep }) => {
  return (
    <Wizard title="Select Classification" titleEs="Seleccione la clasificación" back={ () => setStep("confirm-active-status") }>
      <div className="SelectClass">
        <SpaceBetween direction="vertical" alignment="center">
          <p>
            <Translate language="en">
              Click on one of the following member classifications:
            </Translate>
            <Translate language="es">
              Haga clic en una de las siguientes clasificaciones de miembros:
            </Translate>
          </p>
          <SpaceBetween direction="vertical" size="xxl" alignment="center">
            <SpaceBetween direction="horizontal" size="xxl" alignment="center">
              <Button
                className="SelectClass-button"
                click={() => setStep("journeyman-apprentice")}
                secondary
              >
                <Translate language="en">
                  JOURNEYMAN / APPRENTICE
                </Translate>
                <Translate language="es">
                  OFICIAL / APRENDIZ
                </Translate>
              </Button>
              <Button
                className="SelectClass-button"
                secondary
                click={() => setStep("65-30-retiree")}
              >
                <Translate language="en">
                  65/30 RETIREE
                </Translate>
                <Translate language="es">
                  JUBILADO 65/30
                </Translate>
              </Button>
            </SpaceBetween>
          </SpaceBetween>
        </SpaceBetween>
      </div>
    </Wizard>
  )
}