import React from 'react';

import Translate from 'containers/Translate';
import Hero from 'components/Hero';
import Button from 'components/Button';
import Wizard from 'components/Wizard';
import { SpaceBetween } from 'components/SpaceBetween';

export const SuspendedMembership = ({ setStep }) => {
  return (
    <Wizard title="Suspended membership" titleEs="Membresía suspendida" back={() => setStep("confirm-active-status")}>
      <SpaceBetween direction="vertical" alignment="center">
        <p>
          <Translate language="en">
            Check your status on your TVC card, if you are SUSPENDED you cannot pay online and must call <a href="tel:3017356660">(301) 735-6660</a> for the DC Area, and <a href="tel:4103555555">(410) 355-5555</a>, for the Baltimore Area during office hours.
          </Translate>
          <Translate language="es">
            Verifique su estado en su tarjeta TVC, si está SUSPENDIDO no puede pagar en línea y debe llamar al <a href="tel:3017356660">(301) 735-6660</a> para el área de DC, y <a href="tel:4103555555">(410) 355-5555</a>, para el área de Baltimore durante el horario de oficina.
           </Translate>
        </p>
      </SpaceBetween>
    </Wizard>
  )
}