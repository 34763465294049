import React from 'react';

import Translate from 'containers/Translate';
import Button from 'components/Button';
import Wizard from 'components/Wizard';
import { SpaceBetween } from 'components/SpaceBetween';

export const ConfirmActiveStatus = ({ setStep }) => {
  return (
    <Wizard title="Confirm active membership" titleEst="Confirmar membresía activa"  back={ () => setStep("home") }>
      <div className="ConfirmActiveStatus">
        <SpaceBetween direction="vertical" alignment="center">
          <p>
            <Translate language="en">
              Are you up to date on your membership dues?
            </Translate>
            <Translate language="es">
              ¿Estás al día con tus cuotas de membresía?
            </Translate>
          </p>
          <SpaceBetween direction="vertical" size="xxl" alignment="center">
            <SpaceBetween direction="horizontal" size="xxl" alignment="center">
              <Button
                click={() => setStep("select-class") }
              >
                <Translate language="en">
                  Yes
                </Translate>
                <Translate language="es">
                  Sí
                </Translate>
              </Button>
              <Button
                click={() => setStep("select-class")}
                secondary
              >
                <Translate language="en">
                  No, my membership expired less than 6 months ago
                </Translate>
                <Translate language="es">
                  No, mi membresía expiró hace menos de 6 meses
                </Translate>
              </Button>
              <Button
                click={() => setStep("suspended-membership")}
                secondary
              >
                <Translate language="en">
                  No, my membership expired over 6 months ago
                </Translate>
                <Translate language="es">
                  No, mi membresía expiró hace más de 6 meses
                </Translate>
              </Button>
            </SpaceBetween>
          </SpaceBetween>
        </SpaceBetween>
      </div>
    </Wizard>
  )
}